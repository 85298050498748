import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"; //https://www.npmjs.com/package/vuex-persistedstate
import platform from "platform";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['user', 'admin','darkTheme', 'miniDrawer']
  })],

  state: {
    connection: navigator.connection && navigator.connection.effectiveType,
    ipinfo: null,

    darkTheme: false,
    miniDrawer: false,
    updateInProgress: false,

    user: null,
    admin: null,

    api_calls: 0,

    notifications: [],
  },
  getters: {
    device(state) {
      return {
        manufacturer: platform.manufacturer,
        product: platform.product,
        os: platform.os.toString()
      };
    },
    connection(state) {
      return state.connection;
    },
    ipinfo(state) {
      return state.ipinfo;
    },

    updateInProgress(state) {
      return state.updateInProgress;
    },

    notifications(state) {
      return state.notifications;
    },

    darkTheme(state) {
      return state.darkTheme;
    },

    miniDrawer(state) {
      return state.miniDrawer;
    },

    admin(state) {
      return state.admin;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.user && state.user.token;
    },
    authorized(state) {
      if (!state.user) {
        return false;
      }
      if (!state.user.token) {
        return false;
      }
      if(!state.admin){
        return false;
      }
      return true;
    },

    loading(state) {
      return state.api_calls > 0;
    },

    randomColor() {
      const colors = ["red", "green", "blue", "purple", "pink", "teal", "indigo", "orange", "blue-grey", "cyan", "deep-purple","light-blue","amber","deep-orange"];
      let color = colors[Math.floor(Math.random() * colors.length)];
      let rand = Math.random() * (4 - 1) + 1;
      return `${color} darken-${rand}`;
    }
  },

  mutations: {
    SET_CONNECTION(state, connection) {
      state.connection = connection;
    },
    
    UPDATE_IPINFO(state, payload) {
      state.ipinfo = payload;
    },

    ADD_NOTIFICATION(state, payload) {
      state.notifications.push({
        id: new Date().toISOString(),
        show: true,
        auto_dismiss: true,
        ...payload
      });
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notifications = state.notifications.filter(i => i.id !== id);
    },
    CLEAR_NOTIFICATIONS(state, payload) {
      state.notifications = [];
    },

    UPDATE_FOUND(state) {
      state.updateInProgress = true;
    },
    UPDATED_PWA(state) {
      state.updateInProgress = false;
    },

    UPDATE_MINI(state, mini) {
      state.miniDrawer = mini;
    },

    UPDATE_DARK(state, dark) {
      state.darkTheme = dark;
    },

    UPDATE_USER(state, payload) {
      state.user = payload
    },

    UPDATE_ADMIN(state, payload) {
      state.admin = payload
    },

    ADD_API_CALL(state) {
      state.api_calls++;
    },
    REMOVE_API_CALL(state) {
      if (state.api_calls > 0) state.api_calls--;
    },
  },

  actions: {},
  modules: {}
})